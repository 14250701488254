#CtaTelWpp {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#CtaTelWpp .text {
  text-align: center;
}

#CtaTelWpp .cta {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #CtaTelWpp .cta {
    flex-direction: column;
    gap: 1rem;
  }

  #CtaTelWpp .cta #ctaWpp,
  #CtaTelWpp .cta #ctaTel {
    width: 50%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {

  #CtaTelWpp .cta #ctaWpp,
  #CtaTelWpp .cta #ctaTel {
    width: 75%;
  }
}