#ctaWpp {
    background-color: #27EF9E;
    font-weight: 700;
    padding: 1.4rem .5rem;
    width: 25%;
    height: fit-content;
    border-radius: 6px;
}

#ctaWpp a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: black;
}

#ctaWpp a figure {
    width: 6%;
}

#ctaWpp a figure img {
    width: 100%;
    display: block;
}

#ctaWpp p {
    font-size: 21px;
    font-weight: 700;
    color: black;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp {
        width: 50%;
    }

    #ctaWpp p {
        font-size: 20px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaWpp {
        width: 75%;
        padding: 1rem .5rem;
    }

    #ctaWpp p {
        font-size: 18px;
    }
}