.container .carousel {
  width: 100%;
}

.container .carousel .item {
  display: flex;
  flex-direction: column;
  height: 640px;
}

.container .carousel .item .bottom {
  padding: 2rem 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

.container .carousel .item .item_title {
  color: #0A88E4;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.container .carousel .item .item_text {
  color: #455668;
  text-align: center;
  font-size: 18px;
}

.container .carousel .item #ctaWpp {
  width: 70%;
  margin-top: auto;
}

.container .carousel .slick-slide {
  padding: 0 10px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}