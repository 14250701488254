#menu {
    background-color: black;
}

#menu .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem 0;
}

#menu .container .right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-left: 3rem;
}

#menu .container .right figure {
    text-align: right;
}

#menu .container .right figure img {
    width: 10%;
}

#menu .container .right .text {
    color: white;
    font-weight: bold;
    text-align: right;
    font-size: 16px;
    width: fit-content;
    white-space: nowrap;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container .right {
        grid-template-columns: 10% 1fr;
    }

    #menu .container .right figure img {
        width: 100%;
    }

    #menu .container .right .text {
        white-space: wrap;
        max-width: 200px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container {
        grid-template-columns: 20% 1fr;
    }

    #menu .container .right {
        grid-template-columns: 13% 1fr;
    }

    #menu .container .right .text {
        max-width: 500px;
        font-size: 14px;
        text-align: left;
    }
}