#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 85vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
}

#header .header1 .container .logo {
    position: absolute;
    text-align: left;
    top: 3vh;
    left: 16vh;
    width: fit-content;
}

#header .header1 .container .logo img {
    width: 90%;
}

#header .header1 .container .title {
    text-align: left;
    max-width: 700px;
    font-size: 43px;
    color: white;
    text-transform: initial;
    line-height: 80px;
}

#header .header1 .container .title span {
    font-size: 120px;
    background: linear-gradient(90deg, #03A3F7, #28F19C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    margin-left: -.4rem;
}

#header .header1 .container .text {
    color: white;
    font-weight: 200;
    max-width: 450px;
}

#header .header2 .container .text {
    margin: 0 auto;
    max-width: 1000px;
    text-align: center;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 90vh;
    }

    #header .header1 .container {
        justify-content: flex-start;
    }

    #header .header1 .container .logo {
        top: 2vh;
        left: 7.5vw;
    }

    #header .header1 .container .logo img {
        width: 60%;
    }

    #header .header1 .container .title {
        text-align: center;
        max-width: 100%;
        font-size: 33px;
        line-height: 60px;
        margin-top: 2rem;
    }

    #header .header1 .container .title span {
        font-size: 90px;
    }

    #header .header1 .container .text {
        margin-top: auto;
        text-align: center;
        max-width: 100%;
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
    }

    #header .header2 .container .text {
        max-width: 100%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 75vh;
    }

    #header .header1 .container .logo {
        top: 4vh;
        width: fit-content;
    }

    #header .header1 .container .logo img {
        width: 40%;
    }

    #header .header1 .container .title {
        font-size: 23px;
        line-height: 45px;
    }

    #header .header1 .container .title span {
        font-size: 64px;
    }

    #header .header2 .container .title {
        font-size: 30px;
    }
}