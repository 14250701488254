#about .about1 {
  background-color: #F2FAFF;
}

#about .about2 .container .gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 2rem 0;
  gap: 1rem;
}

#about .about2 .container .gallery .item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

#about .about2 .container .gallery .item figure img {
  width: 40%;
}

#about .about2 .container .gallery .item .item_title {
  color: #0A88E4;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: -1rem;
}

#about .about2 .container .gallery .item .item_text {
  color: #455668;
  text-align: center;
  font-size: 16px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about2 .container .gallery {
    grid-template-columns: 1fr;
  }

  #about .about2 .container .gallery .item figure img {
    width: 25%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .mobile {
    display: grid;
    gap: 2rem;
  }


  #about .about1 .container .mobile .item {
    display: flex;
    flex-direction: column;
  }

  #about .about1 .container .mobile .item .bottom {
    padding: 2rem 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    height: 100%;
    border-radius: 0 0 10px 10px;
    gap: 1rem;
  }

  #about .about1 .container .mobile .item .item_title {
    color: #0A88E4;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  #about .about1 .container .mobile .item .item_text {
    color: #455668;
    text-align: center;
    font-size: 16px;
  }

  #about .about1 .container .mobile .item #ctaWpp {
    width: 75%;
    margin-top: 1rem;
  }
}