#footer .footer1 .container figure img {
    width: 15%;
}

#footer .footer2 {
    border-top: 1px solid black;
}

#footer .footer2 .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer2 .container p {
    font-size: 16px;
    text-align: center;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container figure img {
        width: 20%;
    }

    #footer .footer2 .container p {
        font-size: 14px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container figure img {
        width: 25%;
    }

    #footer .footer2 .container p {
        font-size: 12px;
    }
}